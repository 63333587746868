import { PrivacyWrapper } from "../Styles/Privacy-Style";



function PrivacyPage() {
    return(
        <>
            <PrivacyWrapper>
                <div className="page_inner">
                    <div className="page_head">
                        <h2>
                            <span>Privacy</span>
                            <span>Policy</span>
                            <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                        <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                        <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(15 50)" strokeWidth="0"></path>
                            </svg>
                        </h2>
                    </div>
                    <div className="page_content">
                        <li>
                            <h5>1. Information We Collect</h5>
                            <p><i className="fa-solid fa-minus"></i>Personal Information: We collect personal details such as your name, email address, shipping address, phone number, and payment information when you make a purchase, create an account, or contact us.</p>
                            <p><i className="fa-solid fa-minus"></i>Personal Information: We collect personal details such as your name, email address, shipping address, phone number, and payment information when you make a purchase, create an account, or contact us.</p>
                        </li>
                        <li>
                            <h5>2. How We Use Your Information</h5>
                            <p><i className="fa-solid fa-minus"></i>To Process Orders: We use your personal information to process and fulfill your orders.</p>
                            <p><i className="fa-solid fa-minus"></i>To Communicate: We use your contact information to send you updates about your tickets, respond to inquiries, and send promotional materials if you have opted in.</p>
                            <p><i className="fa-solid fa-minus"></i>To Improve Our Services: We analyze non-personal information to understand user behavior and enhance our website’s performance.</p>
                        </li>
                        <li>
                            <h5>3. Information Sharing</h5>
                            <p><i className="fa-solid fa-minus"></i>Third-Party Service Providers: We may share your information with third-party service provides   who assist us in operating our website, processing payments, and delivering tickets.</p>
                            <p><i className="fa-solid fa-minus"></i>Legal Requirements: We may disclose your information if required by law or to protect our rights.</p>
                        </li>
                        <li>
                            <h5>4. Data Security</h5>
                            <p><i className="fa-solid fa-minus"></i>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>
                        </li>
                        <li>
                            <h5>5. Your Rights</h5>
                            <p><i className="fa-solid fa-minus"></i>Access and Correction: You have the right to access and correct your personal information. You  can update your account details through our website.</p>
                            <p><i className="fa-solid fa-minus"></i>Opt-Out: You can opt-out of receiving promotional emails by following the unsubscribe instructions in the emails.</p>
                        </li>
                        <li>
                            <h5>6. Changes to This Policy</h5>
                            <p><i className="fa-solid fa-minus"></i>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised date will be indicated at the top of the policy.</p>
                        </li>
                    </div>
                </div>
            </PrivacyWrapper>
        </>
    );
}


export default PrivacyPage;