import { styled } from "@mui/material";


export const PrivacyWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding: 100px;
    padding-top: 150px;

    .page_inner {
        position: relative;
        width: 100%;

        .page_head {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                position: relative;
                display: flex;
                align-items: center;

                span:nth-of-type(1) {
                    position: relative;
                    font-size: 55px;
                    font-weight: 700;
                    font-family: arista-pro-trial-bold;
                    color: #000;
                    z-index: 1;
                }

                span:nth-of-type(2) {
                    position: relative;
                    margin-left: 20px;
                    font-size: 55px;
                    font-family: arista-pro-alt-bold;
                    color: #FC6736;
                }

                svg {
                    position: absolute;
                    left: -50px;
                    width: 300px;
                    overflow: inherit;
                }

                @media (max-width: 1399px) {
                    span:nth-of-type(1) {
                        font-size: 45px;
                    }

                    span:nth-of-type(2) {
                        font-size: 45px;
                    }

                    svg {
                        left: -50px;
                        width: 250px;
                    }
                }

                @media (max-width: 575px) {
                    span:nth-of-type(1) {
                        font-size: 35px;
                    }

                    span:nth-of-type(2) {
                        font-size: 35px;
                    }

                    svg {
                        left: -50px;
                        width: 200px;
                    }
                }

                @media (max-width: 450px) {
                    span:nth-of-type(1) {
                        font-size: 30px;
                    }

                    span:nth-of-type(2) {
                        font-size: 30px;
                    }

                    svg {
                        left: -20px;
                        width: 180px;
                    }
                }
            }
        }

        .page_content {
            position: relative;
            margin-top: 100px;
            width: 100%;

            li {
                position: relative;
                width: 100%;
                list-style: none;
                display: flex;
                flex-direction: column;
                margin-bottom: 25px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                h5 {
                    position: relative;
                    font-size: 17px;
                    font-weight: 500;
                    color: #2ad33b;
                    margin-bottom: 15px;
                }

                p {
                    position: relative;
                    width: 100%;
                    margin-bottom: 15px;
                    font-size: 16px;
                    color: #444;
                    line-height: 1.5;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    i {
                        position: relative;
                        font-size: 16px;
                        margin-right: 10px;
                        color: #FC6736;
                    }
                }
            }

            @media (max-width: 575px) {
                margin-top: 60px;
            }

            @media (max-width: 450px) {
                margin-top: 50px;
            }
        }
    }

    @media (max-width: 991px) {
        padding: 80px 50px;
        padding-top: 130px;
    }

    @media (max-width: 575px) {
        padding: 70px 35px;
        padding-top: 120px;
    }

    @media (max-width: 450px) {
        padding: 60px 25px;
        padding-top: 120px;
    }
`;